
.App {
  text-align: center;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.creators-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.creator-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.performance-overview {
  text-align: left;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}